import {Button, Result} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";


export const Success = ({title, subtitle}) => {
    const navigate = useNavigate();
    const goHome = () => {
        navigate("/")
    }

    return (
        <>
            <Result
                status="success"
                title={title}
                subTitle={subtitle}
                extra={[
                    <Button type="primary" key="console" onClick={goHome}>
                        Return to Home
                    </Button>,
                ]}
            />
        </>);

}