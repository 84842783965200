import {Button, Card, Col, ConfigProvider, Descriptions, Flex, Row, Typography} from "antd";
import React from "react";
import Title from "antd/es/typography/Title";
import {FadeInSection} from "../../components/Home/FadeInSection";
import {Content} from "antd/es/layout/layout";
import {Schedule} from "../../components/Home/Schedule";
import {useNavigate} from "react-router-dom";
import {MdOutlineOpenInNew} from "react-icons/md";
import {API_URL} from "../../Util/Constants";

export const CoursePage = ({course}) => {
    const { Text } = Typography;
    const navigate = useNavigate();

    return (<>
            <Content className={"mainPageImage"} style={{position:'absolute', width: '100%', height: 600, display: 'flex', backgroundImage: `url('${API_URL}/${course.image}')`}}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorText: 'white'
                        }
                    }}
                >
                    <div style={{position: "relative"}}>
                        <Flex vertical={'vertical'} style={{width: '100%', height: '100%'}} justify={"center"} align={"center"}>

                            <FadeInSection children={<Title style={{textAlign:'center'}}>{course.name}</Title>} />
                            <FadeInSection children={<Text style={{textAlign:'center', fontFamily: "OswaldLight", fontSize: 24, marginBottom: 60}} level={4}>
                                {course.summary}</Text>} />
                        </Flex>
                    </div>

                </ConfigProvider>

            </Content>
            <Content>
                <Flex vertical={true} style={{marginBottom:48}}>
                    <Row align="center">
                        <Col xs={22} sm={18} md={18} lg={18} xl={18} xxl={14} >
                            <Flex align={"flex-end"} display={"flex"} >
                                <Card style={{marginTop:500}}
                                      actions={[
                                          <Flex style={{fontWeight: 'bold', whiteSpace: 'break-spaces'}} justify={"center"} align={"center"}>
                                              <span  onClick={() => navigate("/host")}>Host {course.name} at Your Agency <span style={{verticalAlign: 'middle'}}><MdOutlineOpenInNew style={{fontSize: '23px', marginLeft: 4}}/></span></span>
                                          </Flex>]}
                                >
                                    <Descriptions layout="vertical" title={<><Title level={4} style={{textAlign: 'center'}}>Course Details</Title><div style={{width:80, margin:'auto'}} className={"header-underline"}></div></>} column={1}  items={[
                                        {
                                            key: '1',
                                            label: 'Course Duration',
                                            children: <Text style={{fontSize:21}}>{course.length} days</Text>,
                                        },
                                        {
                                            key: '2',
                                            label: 'Course Description',
                                            children: <Text style={{ fontSize:21}}>{course.description}</Text>,
                                        },
                                        {
                                            key: '3',
                                            label: 'Upcoming Classes',
                                            children: <Schedule courseId={course.id} />,
                                        }
                                    ]} />
                                </Card>
                            </Flex>
                        </Col>
                    </Row>
                </Flex>

            </Content>

    </>

    );
}