import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import {ConfigProvider, theme} from "antd";
import {AuthProvider} from "react-auth-kit";
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// @ts-ignore
root.render(
      <AuthProvider authType={"cookie"}
                    authName={"auth"}
                    cookieDomain={window.location.hostname}
                    // change to true when deploying
                    cookieSecure={false}
      >
          <BrowserRouter>
              <ConfigProvider
                  theme={{
                      algorithm: theme.darkAlgorithm,
                      token: {
                          colorPrimary: "#0cabc0",
                          colorInfo: "#0cabc0",
                          wireframe: false,
                          borderRadius: 3,
                          fontFamily: 'Oswald',
                          fontSize: 19,
                      },
                      components: {
                         Layout: {
                             bodyBg: '#1e1e1e',
                             headerBg: '#1C1C1CFF',
                             //headerColor: 'white',
                             headerHeight: 80
                         },
                          Menu: {
                             itemBg: '#1c1c1c',
                             // itemColor: 'white',
                              //itemHoverColor: "#0cabc0",
                              //subMenuItemBg: 'rgba(0,0,0,0.3)',
                              //darkSubMenuItemBg: 'rgba(0,0,0,0.3)'
                          }
                      },
                  }}
              ><App /></ConfigProvider>
          </BrowserRouter>
      </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
