import {Col, ConfigProvider, Flex, Layout, Row, Spin, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {Content} from "antd/es/layout/layout";
import {CustomFooter} from "../components/footer";
import {CustomHeader} from "../components/header";
import Title from "antd/es/typography/Title";
import {Instructors} from "../components/Home/Instructors";
import {OfferedCourses} from "../components/Home/OfferedCourses";
import {Schedule} from "../components/Home/Schedule";
import {ContactUs} from "../components/Home/ContactUs";
import {FadeInSection} from "../components/Home/FadeInSection";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import {HomeContent} from "../components/Home/HomeContent";
import {About} from "../components/Home/About";
import {LandingPageActionItems} from "../components/Home/LandingPageActionItems";
import {Testimonials} from "../components/Home/Testimonials";

import {API_URL} from "../Util/Constants";
import {useAxios, useCommonAxiosCalls, useInitialUseEffect} from "../Util/Util";

export const Home = () => {
    const [offeredCourses, setOfferedCourses] = useState(false);
    const [scheduledCourses, setScheduledCourses] = useState(false);
    const [instructors, setInstructors] = useState(false);
    const [settings, setSettings] = useState(false);
    const { pathname, hash, key } = useLocation();
    const {GetCourses, GetInstructors, GetSettings} = useCommonAxiosCalls();
    const {AxiosGet} = useAxios();
    const { Text } = Typography;

    useInitialUseEffect(()=>{
        AxiosGet("scheduledCourses.php", {}, (response) => setScheduledCourses(response.data.value));
        GetSettings(setSettings);
        GetInstructors(setInstructors);
        GetCourses(setOfferedCourses);
    })


    const scrollToHash = () => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }}, 100);
        }

    }

    const getHomeContent = () => {
        return !(offeredCourses && scheduledCourses && instructors) ?
            (<>
                    <CustomHeader activeTab=""/>
                    <Content style={{width: '100%', height: "100%", display: 'flex'}}>
                        <Flex style={{flex:1}} align={"center"} justify={"center"}>
                            <Spin />
                        </Flex>
                    </Content>
            </>
                ) :
            (<>

                <CustomHeader activeTab="" solidBackground={false}/>
                <Content className="homeContent mainPageImage" style={{width: '100%', display: 'flex'}}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorText: 'white'
                        }
                    }}
                >
                    <div style={{position: "relative"}}>
                        <Flex vertical={'vertical'} style={{width: '100%', height: '100%'}} justify={"center"} align={"center"}>

                            <Row align={"center"}>
                                <Col span={20}>
                                    <span style={{display: "block", height: 32}}></span>
                                    <FadeInSection children={<Title className={"main-page-title"}>A New Standard in Law Enforcement Training</Title>} />
                                    <FadeInSection children={<Text className={"main-page-subtitle"}>
                                        Our custom courses address leadership, team dynamics, high-stress decision-making,
                                        and critical incident resolution, using current, nationally accepted best-practice
                                        techniques.</Text>} />
                                    <FadeInSection children={<LandingPageActionItems />} />
                                </Col>
                            </Row>

                        </Flex>
                    </div>
                </ConfigProvider>

            </Content>
                <HomeContent
                    id={"about"}
                    isEven={true}
                    title={"Practical Training from Experienced Instructors"}
                    subtitle={"Equip your team members with effective leadership skills and foster an environment of collaboration."}
                    child={<About />}>
                </HomeContent>
                <HomeContent
                    id={"testimonials"}
                    isEven={false}
                    title={"Testimonials"}
                    subtitle={false}
                    child={<Testimonials />}>
                </HomeContent>
                <HomeContent
                    id={"instructors"}
                    isEven={true}
                    title={"Meet Our Instructors"}
                    subtitle={"Our instructors' real-world insights, gained through years of involvement in " +
                        "patrol and tactical operations and dynamic critical incident planning, " +
                        "allow them to bring a depth of knowledge and understanding to the training environment."}
                    child={<Instructors instructors={instructors} />}>
                </HomeContent>
                <HomeContent
                    id={"offered"}
                    isEven={false}
                    title={"Offered Courses"}
                    subtitle={"Current, nationally accepted, best-practice techniques and tactics, providing law " +
                        "enforcement professionals with valuable, real-world training."}
                    child={<OfferedCourses offeredCourses={offeredCourses} />}>
                </HomeContent>
                <HomeContent
                    id={"scheduledCourses"}
                    isEven={true}
                    title={"Upcoming Courses"}
                    child={<Schedule scheduledCourses={scheduledCourses}/>}>
                </HomeContent>
                <HomeContent
                    id={"contact"}
                    isEven={false}
                    title={false}
                    subtitle={false}
                    child={<ContactUs contactEmail={settings.contact_email}/>}>
                </HomeContent>
                <CustomFooter instructors={instructors} offeredCourses={offeredCourses}/>
                {scrollToHash()}
            </>);
    }

    return (
        <Layout className="layout">
            {getHomeContent()}
        </Layout>
    )
}