import React from 'react';
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/home";
import {Instructor} from "./pages/dynamic/Instructor";
import {OfferedCourse} from "./pages/dynamic/OfferedCourse";
import {Host} from "./pages/host";
import {App as AntApp} from "antd";


function App() {
    return (
        <AntApp>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='host' element={<Host />} />
                <Route path='instructor/:id' element={<Instructor />} />
                <Route path='course/:id' element={<OfferedCourse />} />
            </Routes>
        </AntApp>
  );
}

export default App;

