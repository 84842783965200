import {useParams} from "react-router-dom";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {CustomHeader} from "../../components/header";
import {Content} from "antd/es/layout/layout";
import {Flex, Layout, Spin} from "antd";
import {CustomFooter} from "../../components/footer";
import {CoursePage} from "./CoursePage";
import {API_URL} from "../../Util/Constants";
import {useAxios, useInitialUseEffect} from "../../Util/Util";

export const OfferedCourse = () => {
    const { id } = useParams();
    const [course, setCourse] = useState(false);
    const {AxiosGet} = useAxios();

    useInitialUseEffect(()=>{
        AxiosGet(`courses.php`, {id: id}, (response) => setCourse(response.data.value[0]));
    })

    const getCourseContent = () => {
        return !(course) ?
            (<>
                    <CustomHeader activeTab=""/>
                    <Content style={{width: '100%', height: "100%", display: 'flex'}}>
                        <Flex style={{flex:1}} align={"center"} justify={"center"}>
                            <Spin />
                        </Flex>
                    </Content>
                </>
            ) :
            (<>
                <CustomHeader activeTab="" solidBackground={false}/>
                    <CoursePage course={course}/>
                <CustomFooter/>
            </>);
    }

    return (
        <Layout className="layout">
            {getCourseContent()}
        </Layout>);
}