import {Button, Card, Col, Flex, Row} from "antd";
import Title from "antd/es/typography/Title";
import {ArrowRightOutlined} from "@ant-design/icons";
import React from "react";
import Meta from "antd/es/card/Meta";
import {HashLink} from 'react-router-hash-link';

export const LandingPageActionItems = () => {
    const cardStyle = { flex:1};

    return (<Row justify={"center"} style={{columnGap:36, rowGap:16}} >

        <Col style={{display: 'flex' , alignItems: "stretch", padding: 0}} xs={20} sm={18} md={18} lg={18} xl={6}>

            <HashLink to="#scheduledCourses" style={{display: 'flex', flex: 1}}>
                <Card hoverable={true} bordered={false} style={cardStyle} className={"buttonCard"}>
                    <Meta title={
                        <Flex justify={"space-between"} align={"flex-start"}>
                            <Title style={{margin: "0 0 8px", whiteSpace: 'break-spaces'}} level={4}>View Upcoming Courses</Title>
                            <Button type="ghost" icon={<ArrowRightOutlined/>}></Button>
                        </Flex>
                    }
                          description="See our course schedule and register for courses."
                    />
                </Card>
            </HashLink>

        </Col>
        <Col style={{display: 'flex', alignItems: "stretch", padding: 0}} xs={20} sm={18} md={18} lg={18} xl={6}>
            <HashLink to="#offered" style={{display: 'flex', flex: 1}}>
                <Card hoverable={true} bordered={false} style={cardStyle} className={"buttonCard"}>
                    <Meta title={
                        <Flex justify={"space-between"} align={"flex-start"}>
                            <Title style={{margin: "0 0 8px", whiteSpace: 'break-spaces'}} level={4}>Explore Offered Courses</Title>
                            <Button type="ghost" icon={<ArrowRightOutlined/>}></Button>
                        </Flex>
                    }
                          description="Read about our offered courses and apply to host a course."
                    />
                </Card>
            </HashLink>
        </Col>
        <Col style={{display: 'flex', alignItems: "stretch", padding: 0}} xs={20} sm={18} md={18} lg={18} xl={6}>
            <HashLink to="#instructors" style={{display: 'flex', flex: 1}}>
                <Card hoverable={true} bordered={false} style={cardStyle} className={"buttonCard"}>
                    <Meta title={
                        <Flex justify={"space-between"} align={"flex-start"}>
                            <Title style={{margin: "0 0 8px", whiteSpace: 'break-spaces'}} level={4}>Meet our Instructors</Title>
                            <Button type="ghost" icon={<ArrowRightOutlined/>}></Button>
                        </Flex>
                    }
                          description="Meet our team of highly skilled, experienced,
                                              actively serving police officers based in the North Texas area."
                    />
                </Card>
            </HashLink>
        </Col>

    </Row>);
}