import {Button, Card, Col, Flex, Row, Typography} from "antd";
import React from "react";
import {FacebookOutlined, InstagramOutlined, LinkedinOutlined, MailOutlined, TwitterOutlined} from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import {Content} from "antd/es/layout/layout";
import {API_URL} from "../../Util/Constants";

export const InstructorPage = ({instructor}) => {
    const { Text } = Typography;
    const fullName = instructor.first_name + " " + instructor.last_name;


    const getInstaButton = () => {
        if(instructor.instagram?.trim().length > 0){
            return (<Button style={{textAlign: 'left'}} type={"ghost"} icon={<InstagramOutlined/>}
                            href={addHttpPrefix(instructor.instagram)} target="_blank"></Button>)
        }
    }

    const getLinkedInButton = () => {
        if(instructor.linkedin?.trim().length > 0){
            return (<Button style={{textAlign: 'left'}} type={"ghost"} icon={<LinkedinOutlined/>}
                            href={addHttpPrefix(instructor.linkedin)} target="_blank"></Button>);
        }
    }

    const getTwitterButton = () => {
        if(instructor.twitter?.trim().length > 0){
            return (<Button style={{textAlign: 'left'}} type={"ghost"} icon={<TwitterOutlined/>}
                            href={addHttpPrefix(instructor.twitter)} target="_blank"></Button>);
        }
    }

    const getFacebookButton = () => {
        if(instructor.facebook?.trim().length > 0){
            return (<Button style={{textAlign: 'left'}} type={"ghost"} icon={<FacebookOutlined/>}
                            href={addHttpPrefix(instructor.facebook)} target="_blank"></Button>);
        }
    }

    const getEmailButton = () => {
        return (<Button style={{display:'flex', justifyContent:'center', alignItems:'center'}} shape={"circle"} icon={<MailOutlined style={{fontSize:18}}/>}
                        href={"mailto:" + instructor.email_address} target="_blank"></Button>);

    }

    const addHttpPrefix = (url) => {
        if(url.includes("http")){
            return url;
        } else {
            return "http://".concat(url);
        }
    }

    return (
        <Content className={"homeContentEven"} style={{paddingTop: 136, paddingBottom: 84}}>
            <Flex vertical={true} gap={"large"}>
                <Row align="center" gutter={[36, 72]}>
                    <Col xs={20} sm={18} md={14} lg={14} xl={12} xxl={10} >
                        <Flex align={"flex-end"} display={"flex"} gap={"large"}>
                            <img style={{width:'50%', height:'auto'}} alt={fullName} src={API_URL.concat("/" + instructor.image)} />
                            <Flex vertical={true} align={"flex-start"} >
                                <Title level={3}>{fullName}</Title>
                                <Text >{instructor.title}</Text>
                                <div style={{width:80, marginTop:24}} className={"header-underline justify-left"}></div>
                                <Flex justify={"flex-start"} style={{width: '100%'}} >
                                    {getInstaButton()}
                                    {getFacebookButton()}
                                    {getLinkedInButton()}
                                    {getTwitterButton()}
                                    {getEmailButton()}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Col>
                </Row>
                <Row align={"center"}>
                    <Col xs={20} sm={18} md={14} lg={14} xl={12} xxl={10}>
                        <Card
                        >
                            <Text style={{lineHeight:2, fontSize:21}} > {instructor.summary} </Text>
                        </Card>
                    </Col>
                </Row>

            </Flex>
        </Content>
    );
}