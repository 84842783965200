import React, {useState} from "react";
import {Content} from "antd/es/layout/layout";
import {Card, Col, Flex, Row, Typography} from "antd";
import {RiDoubleQuotesL, RiDoubleQuotesR} from "react-icons/ri";

export const Testimonials = () => {
    const [testimonialCards, setTestimonialCards] = useState(false);

    const testimonials = [
        "Great class! The Case Law and Safety Priorities application were extremely valuable and insightful in how they drive our decisions.",

        "The course opened my eyes to some opportunity of growth within my unit that will be addressed and implemented moving forward.",

        "Great class. I wasn’t sure what to expect, but I took a lot away from the class. I am attempting to " +
            "develop standards and a better training program for my unit, and I believe the information and shared documentation will help with that.",

        "The instructors were very knowledgeable and presented materials and information that made it clear and understandable. I would recommend this course to any leaders within my organization.",

        "This was one of the best tactical “classroom” courses that I have attended. The portions on " +
        "leadership, documentation, selection, and training were particularly helpful. I think this class has " +
        "broad applications beyond tactical teams. This will absolutely help me in running my team. I greatly " +
        "appreciate the instructor’s knowledge and experience and their ability to communicate it with the " +
        "class.",

        "I highly recommend this class not only on the tactical side but as well for the patrol side due to the\n" +
        "breakdown of leadership skills and governmental interest while dealing with barricaded persons\n" +
        "incidents."
    ]

    const getTestimonialCard = (testimonial) => {
        return (
            <Col key={testimonials.indexOf(testimonial)} style={{display: 'flex', alignItems: "stretch"}}
                 sm={20}
                 md={20}
                 lg={12}
                 xxl={8}>
                <Card style={{display:'flex'}} bodyStyle={{flex:1, display:'flex'}}>
                    <Flex gap={"small"} vertical={true}>
                        <RiDoubleQuotesL className={"primary-color"}/>
                        <Flex align={"center"} style={{flex:1}}>
                            <Typography.Text italic={true} style={{textAlign:'center'}}>{testimonial}</Typography.Text>
                        </Flex>

                        <Flex justify={"flex-end"} align={"flex-end"}>
                            <RiDoubleQuotesR style={{alignSelf:'flex-end'}} className={"primary-color"}/>
                        </Flex>
                    </Flex>

                </Card>
            </Col>
        );
    }

    const getTestimonialCards = () => {
        const testimonialCards = [];
        testimonials.forEach(testimonial => {
            testimonialCards.push(getTestimonialCard(testimonial));
        });
        setTestimonialCards(testimonialCards);

    }


    return (
        <Content>
            <Row gutter={[36, 36]} align={"center"}>
                {testimonialCards ? testimonialCards : getTestimonialCards()}
            </Row>
        </Content>
    )
}